.button {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  min-height: var(--buttonHeight);
  padding: 0 var(--s1);
  border: 0;
  background-color: transparent;
  color: var(--customColor, var(--white));

  /* These are set in the `style` prop ---------------------------------------- */
  font-family: var(--mainFontFamily);
  font-size: var(--regularFontSize);
  /* -------------------------------------------------------------------------- */

  transition-property: opacity, background, border, clip-path;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

/* .button * {
  Handle hiding that the button text might have needed to change color
  animation: fade-in 150ms;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

/* Version Styles ----------------------------------------------------------- */
.button.primaryGhost {
  border: solid 2px var(--primaryColor, "black");
  color: var(--primaryColor);
}

.button.secondaryGhost {
  border: solid 2px var(--secondaryColor, "gray");
}

.button.blackGhost {
  border: solid 2px var(--darkBlack, "black");
}

.button.whiteGhost {
  border: solid 2px var(--white, "white");
}
/* -------------------------------------------------------------------------- */

/* Interaction Styles ------------------------------------------------------- */
.button:not([disabled]) {
  cursor: pointer;
}

.button:hover:not([disabled]),
.button:focus:not([disabled]) {
  opacity: 0.7;
}

.button:active:not([disabled]) {
  opacity: 0.5;
}

.button:disabled {
  opacity: 0.8;
}
/* -------------------------------------------------------------------------- */
