.inputWrapper {
  --labelSize: 1.8rem;
  --labelSizeShrunk: 1.4rem;
  --labelShrunkTop: calc((var(--inputHeight) / 3.5) - (var(--labelHeight) / 1.5));

  position: relative;
}

.inputWrapper > .label {
  user-select: none;
  display: flex;
  align-items: center;
  height: 20px;

  position: absolute;
  top: calc((var(--inputHeight) / 2) - (var(--labelHeight) / 2));
  z-index: 1;
  color: var(--darkGray);
  transition: font-size 0.17s, top 0.12s ease-in-out, color 0.15s ease-in-out;
  left: var(--inputLeftPadding);
  font-size: var(--labelSize);
}

.inputWrapper > input {
  width: 100%;
  min-height: calc(var(--inputHeight) - 2px);
  height: 100%;
  padding: 15px 0 0 var(--inputLeftPadding);
  border: none;
  border-bottom: 1px solid var(--darkGray);
}

.inputWrapper > input[type="date"] {
  /* For mobile safari ------------ */
  min-width: 100%;
  appearance: textfield;
  -webkit-appearance: textfield;
  /* ------------------------------ */
}

.inputWrapper.shrink > label {
  top: var(--labelShrunkTop);
  font-size: var(--labelSizeShrunk);
}

.inputWrapper.showBorder > input {
  border: 1px solid var(--darkGray);
}

.inputWrapper.isDisabled > input {
  color: var(--darkGray);
}

.inputWrapper:not(.isDisabled) > .label {
  cursor: text;
  pointer-events: none;
}

.inputWrapper:not(.isDisabled):hover > .label {
  color: var(--lightBlack);
}

.inputWrapper > input:not(:placeholder-shown) + label {
  top: var(--labelShrunkTop);
  font-size: var(--labelSizeShrunk);
}

.inputWrapper:focus-within > .label {
  top: var(--labelShrunkTop);
  font-size: var(--labelSizeShrunk);
  color: var(--lightBlack);
}

.inputWrapper.error > .label {
  color: var(--errorColor) !important;
}

.inputWrapper.error > input {
  border: 1px solid var(--errorColor);
}

.inputWrapper .remainingChars {
  position: absolute;
  right: 9px;
  bottom: 6px;
  padding: 0 6px;
}

/* Clears the 'x' in the `type="search"` inputs from Internet Explorer */
.inputWrapper > input[type="search"]::-ms-clear,
.inputWrapper > input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* Clears the 'x' in the `type="search"` inputs from Chrome */
.inputWrapper > input[type="search"]::-webkit-search-decoration,
.inputWrapper > input[type="search"]::-webkit-search-cancel-button,
.inputWrapper > input[type="search"]::-webkit-search-results-button,
.inputWrapper > input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
