.dateInput {
  display: flex;
  gap: var(--s0);
}

.dateInput > *:nth-child(1) {
  width: 80px;
}

.dateInput > *:nth-child(2) {
  width: 60px;
}

.dateInput > *:nth-child(3) {
  width: 60px;
}