.waiverPage {
  position: relative;
}

.waiverPage .waiver {
  padding-bottom: var(--s5);
}

.waiverPage .clearButton {
  color: var(--primaryColor);
  font-weight: bold;
  position: absolute;
  right: 0;
}

.waiverPage .signatureCustomClass {
  height: 140px;
  border-bottom: 2px solid var(--darkBlack);
}

.waiverPage .bottom {
  display: flex;
  justify-content: space-between;
}

.waiverPage .bottom .footnote {
  margin-top: var(--s1);
}

.waiverPage .bottom .signatureDetails {
  margin-top: var(--s1);
  display: flex;
  gap: var(--s4);
  font-weight: bold;
}

.waiverPage .bottom .toTopButton {
  align-self: flex-end;
  color: var(--white);
}
