.userCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--lightGray);
  padding: var(--s2);
}

.userCard .left {
  display: flex;
  flex-direction: column;
  gap: var(--s1);
}

.userCard .left > div {
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
}

.userCard .name * {
  font-weight: bold;
  font-size: var(--h4FontSize);
}

.userCard .name.row {
  flex-direction: row;
}
