.summaryPage {
  display: flex;
  flex-direction: column;
  gap: var(--s2);
}

.summaryPage p {
  font-size: var(--h4FontSize);
}

.summaryPage .doneButton {
  max-width: fit-content;
  display: flex;
  font-weight: bold;
  gap: var(--s0);
}
