.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--headerHeight);
}

svg {
  --size: 24px;
  width: var(--size);
  height: var(--size);
}
