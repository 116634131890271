:root {
  /* ─── Colors ─────────────────────────────────────────────────────── */
  /* Color Names */
  --darkBlue: #00a6e1;
  --lightBlue: #0ebef0;
  --red: #ce0000;

  --darkBlack: #00000c;
  --lightBlack: #3a3a3c;
  --darkGray: #626874;
  --lightGray: #f2f2f6;
  --white: #fff;

  /* Color Scheme */
  --primaryColor: var(--darkBlue);
  --primaryColorAccent: var(--lightBlue);
  --secondaryColor: var(--darkBlack);
  --tertiaryColor: var(--lightGray);
  --errorColor: var(--red);
  /* ────────────────────────────────────────────────────────────────── */

  /* ─── Sizing ─────────────────────────────────────────────────────── */
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s6: calc(var(--s5) * var(--ratio));
  --s7: calc(var(--s6) * var(--ratio));
  --s8: calc(var(--s7) * var(--ratio));

  --appWrapperMaxWidth: 820px;
  --bodyPadding: var(--s1);
  --headerHeight: var(--s5);
  --buttonHeight: var(--s4);
  --inputHeight: var(--s4);
  --labelHeight: 20px;
  --inputLeftPadding: 9px;
  --standardBorderRadius: 5px;
  /* ────────────────────────────────────────────────────────────────── */

  /* ─── Typography ─────────────────────────────────────────────────── */
  --mainFontFamily: "Lato", sans-serif;
  --h1FontSize: 3.5rem;
  --h2FontSize: 3rem;
  --h3FontSize: 2.5rem;
  --h4FontSize: 2rem;
  --h5FontSize: 1.8rem;
  --regularFontSize: 1.6rem;
  /* ────────────────────────────────────────────────────────────────── */

  /* ─── Animation ──────────────────────────────────────────────────── */
  --standardTransitionTime: 250ms;
  /* ────────────────────────────────────────────────────────────────── */
}
