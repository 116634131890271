.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchPage {
  display: flex;
  flex-direction: column;
  gap: var(--s2);
}

.searchPage p {
  font-size: var(--h4FontSize);
}

.searchPage .noButton {
  width: 100%;
}
