.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.app svg.logo {
  width: 300px;
  height: 300px;
}

.introPageButton {
  width: 250px;
  height: 100px;
  position: absolute;
  bottom: 0px;
  padding: var(--s3);
  display: flex;
  justify-content: space-between;
}

.introPageButton.left {
  left: 0px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.introPageButton.right {
  right: 0px;
}

.app .adminFacing {
  display: flex;
  flex-direction: column;
  gap: var(--s2);
  width: min(100%, 600px);
}

.app .adminFacing .buttons {
  display: flex;
  gap: var(--s1);
}

.app .adminFacing .buttons button {
  max-width: fit-content;
  display: flex;
  font-weight: bold;
  gap: var(--s0);
}

.app .form {
  position: absolute;
  height: var(--buttonHeight);
  width: 1px;
  background-color: var(--primaryColor);
  overflow: visible;
}

.app .form:not(.open) {
  --buttonWidth: 125px;
  width: var(--buttonWidth);
  margin-top: 200px;
}

.app .form:not(.open) > button {
  width: var(--buttonWidth);
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.app .form.open {
  --openSpeed: 500ms;

  height: 100vh;
  width: 100vw;
  background-color: var(--white);

  transition-property: height, width, background-color;
  transition-duration: var(--openSpeed), var(--openSpeed), var(--openSpeed);
  transition-delay: 0ms, 0ms, 250ms;
}

.app .form.open .innerWrapper {
  animation: fade-in calc(2 * var(--openSpeed));
}

.app .form .innerWrapper {
  padding: var(--bodyPadding);
  max-width: var(--appWrapperMaxWidth);
  margin: 0 auto;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
