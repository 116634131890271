@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap");

/************************************************
  CSS RESET
	Eric Meyers' CSS reset and tested
	box-sizing fix popularized by Paul Irish.
************************************************/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
ol,
ul,
li {
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style-type: none;
}
blockquote {
  margin: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/************************************************
  CUSTOM MODIFICATIONS
************************************************/
html {
  font-size: 62.5%; /* This allows you to set rems in pixel sizes (16px => 1.6rem) */
}

body * {
  font-size: var(--regularFontSize);
  font-family: var(--mainFontFamily);
}

h1,
h2,
h3,
h4 {
  line-height: 200%;
  font-weight: bold;
}

h1 {
  font-size: var(--h1FontSize);
}

h2 {
  font-size: var(--h2FontSize);
}

h3 {
  font-size: var(--h3FontSize);
}

h4 {
  font-size: var(--h4FontSize);
}

h5 {
  font-size: var(--h5FontSize);
}

ul,
ol {
  padding: 0;
}

p {
  line-height: 130%;
}

input {
  font-size: var(--h3FontSize);
}

button {
  color: var(--darkBlack);
}
